<template>
      <v-card
        elevation="0"
        :class="$vuetify.breakpoint.xs?'mt-1 text-caption mb-2' :'mt-2 mb-6 mx-0'"
      >
  
       <v-progress-circular
        :rotate="-90"
        :size="$vuetify.breakpoint.xs? 100: $vuetify.breakpoint.mdAndDown? 120 : 140"
        :width="$vuetify.breakpoint.xs? 7 : $vuetify.breakpoint.mdAndDown? 9 : 11"
        :value="this.item.usageRate"
        :indeterminate="this.loading"
        :color="circleColor"
        :class="$vuetify.breakpoint.mdAndDown? 'mx-1':'mx-3'"
      >
        <div class="d-flex flex-column black--text " v-if="!loading">
          <p class="pb-0 ma-0 text-h7 black--text text-center text--secondary font-weight-bold">{{this.item.name}}</p>
        <div class="d-flex pt-2 pl-2 ">
        <p class="body-2 text--primary mt-auto  mb-0 pb-0">
          <span v-if="$vuetify.breakpoint.xs" class="body-2">{{this.item.usage}}</span>
          <span v-else-if="$vuetify.breakpoint.mdAndDown" class="text-h6">{{this.item.usage}}</span>
          <span v-else class="text-h5">{{this.item.usage}}</span>
          </p>
        <p :class="$vuetify.breakpoint.xs? 'mt-auto  mb-0 pb-0 caption':$vuetify.breakpoint.mdAndDown? 'mt-auto  mb-0 pb-1 body-2' :'mt-auto  mb-0 pb-0'  " >
          /{{this.item.limit}}
          <span v-if="this.item.name=='ナビ' | this.item.name=='URL'" >個</span>
          <span v-else>GB</span>
        </p>
      </div>
      </div>
      </v-progress-circular>
      </v-card>

</template>

<script>
  export default {
    name: 'Storage',
    props:["item","loading"],
    data: () => ({
      usageRate:0,
    }),

    computed: {
      circleColor : function(){
        if(this.loading){
          return 'blue-grey lighten-4'
        }
        else if(this.item.name=="ナビ"){
          return 'blue'
        }
        else{
          return 'narekomu'
        }
      }
    },

    mounted() {
      this.item.usage =  Math.round(this.item.usage*1000)/1000;
    },

    watch:{
      loading(){
        this.item.usage =  Math.round(this.item.usage*1000)/1000;
      }
    }
  }
</script>



